import axios from 'axios';
import log from '@/services/logConfig';

export default class BaseApiService {
  constructor() {

    const baseURL = process.env.VUE_APP_API_URL || 'http://localhost:8001/api';
    
    log.debug('Set Base URL as: ', baseURL);

    this.api = axios.create({
      baseURL,
      withCredentials: true
    });

    this.api.interceptors.request.use(
      (config) => {
        log.debug('API Request:', {
          method: config.method.toUpperCase(),
          url: config.baseURL + config.url,
          params: config.params,
          data: config.data
        });
        return config;
      },
      (error) => {
        log.error('Request error:', {
          message: error.message,
          config: error.config
        });
        return Promise.reject(error);
      }
    );

    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        log.error('Response error:', {
          message: error.message,
          url: error.config?.baseURL + error.config?.url,
          method: error.config?.method?.toUpperCase(),
          params: error.config?.params,
          data: error.config?.data,
          status: error.response?.status
        });
        return Promise.reject(error);
      }
    );
  }
}